html, body {
    background-color: #ffffff;
}

input {
    background-color: #f1f1f1 !important
}

#model > canvas {
    background: rgb(238,238,238);
    background: radial-gradient(circle, rgba(238,238,238,1) 0%, rgba(255,255,255,1) 65%, rgba(221,221,221,1) 100%);
}

#model {
    border: 1px solid black;
    position: relative;
}

#inset  {
    width: 200px;
    height: 200px;
    padding: 0px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 100;
}

#inset canvas {
    background-color: rgba(255,255,255,0);
}

.wrap {
    position: relative;
    min-height: 100vh;
}

.main {
    padding-top: 115px;
    padding-bottom: 86px;
}

p {
    text-indent: 25px;
}

footer {
    background-color: #768084 !important;
    padding: 22px;
    font-size: 18px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 86px;            /* Footer height */
}

.icons a{
    margin-bottom: 0px;
    float: right;
    padding-right: 15px;
    display: block;
    font-size: 18px;
    color: #ffffff;
}

.copyright {
    color: #ffffff !important;
    max-width: 250px;
    font-size: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 1.2em;
    padding-top: 3px;
    padding-bottom: 3px;
    -webkit-font-smoothing: subpixel-antialiased;
}

fieldset {
    border: 1px solid #768084 !important;
    padding: 0 1em 0 1em !important;
    margin: 0 0 1.5em 0 !important;
    border-radius: 10px;
}

legend {
    font-size: 14px !important;
    font-weight: bold !important;
    text-align: left !important;
    width:auto;
    padding:0 10px;
    border-bottom:none;
}

.letter_sp_low {
    letter-spacing: -1px;
}

.grey_button {
    border: 1px solid #768084;
    background-color: #aaa;
}

.grey_button:hover {
    background-color: #777;
    border: 1px solid #768084;
}

.grey_button:active, .grey_button:focus {
    background-color: #777 !important; 
    border: 1px solid #768084;
}

.results {
    margin-top: 20px;
}

.results > fieldset > legend {
    font-size: 20px !important;
}


.dropdown:hover>.dropdown-menu {
    display: block;
}

.navbar{
    min-height: 96px;
    background-color: #ffffff;
}

.navbar-brand img{
    height: 34px;
}

.logout-link {
    border: 0;
}

.navbar-nav a, .logout-link{
    font-family: "Open Sans", sans-serif;
    color: #768084;
    text-decoration: none !important; 
    font-weight: 500;
    font-size: 14px;
    line-height: 94px;
    margin-left: 16px;
    margin-right: 16px;
    padding: 0;
    transition: all 0.3s;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}

.nav-link, .logout-link{
    color: #768084 !important;
}

.navbar-nav a:hover, .logout-link:hover{
    color: #aebadf !important;
}

.dropdown-menu{
    height: auto;
    margin: 0;
    box-shadow: none;
    padding: 0;
    background: transparent;
    border: 0px;
    min-width: 230px;
    margin-left: -8px;
    box-shadow: 0 1px 14px rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: 0 !important;
    margin-top: -3px;
    border-top: 3px solid #aebadf;
}

.dropdown-item{
    font-size: 13px  !important;
    line-height: 40px !important;
    padding: 0 15px !important;
    font-weight: 500;
    display: block;
    margin: 0 !important;
    border: 0px;
    border-bottom: 1px solid #f4f4f4 !important;
}

@media(max-width:960px) {
    .navbar-nav a, .logout-link {
        list-style: none;
        line-height: 30px;
        display: block;
        border-bottom: 1px solid #f4f4f4;
        padding: 10px 0;
        background: none !important;
        color: #8c8c8c;
        font-family: "Open Sans", sans-serif;
        font-weight: 900 !important;
        font-size: 18px;
    }
    .dropdown:hover>.dropdown-menu {
        display: none;
    }
}


.red {
    background-color: red;
}
.green {
    background-color: green;
}
.blue {
    background-color: blue;
}
.front {
    background-color: #AEBADF;
}

.sphere {
    display: inline-block;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    text-align: center;
}

.box {
    display: inline-block;
    height: 15px;
    width: 23px;
    text-align: center;
    margin-top: 4px;
}

.sphere_label {
    display: inline-block;
    margin-right: 30px;
    margin-left: 5px;
}