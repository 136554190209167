.grey_button {
    border: 1px solid #768084;
    background-color: #aaa;
}

.grey_button:hover {
    background-color: #777;
    border: 1px solid #768084;
}

.grey_button:active, .grey_button:focus {
    background-color: #777 !important; 
    border: 1px solid #768084;
}

.form {
    margin-top: 100px;
}

.logo {
    width: 120px;
}

.btn-adds{
    list-style: none;
    display: block;
    background: none !important;
    text-decoration: none !important;
    color: #768084;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
}

.btn-adds:hover {
    color: #aebadf !important;
}

.add-link {
    margin-top: 60px;
}